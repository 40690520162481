import { useEffect } from "react";
import keyWeights from "../data/keyWeights";
import { getWeightedRandomIndex } from "../utils";
import { useLocalStorage } from "../hooks";

export default function KeysPage() {
  const [selectedUserKey, setSelectedUserKey] =
    useLocalStorage("selectedUserKey");
  const [userKeyWeights, setUserKeyWeights] = useLocalStorage(
    "userKeyWeights",
    keyWeights
  );

  const selectUserKey = () => {
    const currentUserKeyWeights = userKeyWeights;
    const selectedIndex = getWeightedRandomIndex(
      Object.values(currentUserKeyWeights)
    );
    const selectedK = Object.keys(currentUserKeyWeights)[selectedIndex];
    setSelectedUserKey(selectedK);
    delete currentUserKeyWeights[selectedK];
    setUserKeyWeights(currentUserKeyWeights);
  };

  const resetUserKeys = () => {
    setUserKeyWeights(keyWeights);
  };

  useEffect(() => {
    if (!selectedUserKey) {
      selectUserKey();
    }
  }, [selectedUserKey]);

  return (
    <div>
      <div style={{ paddingTop: "2em" }}>
        {selectedUserKey && (
          <div style={{ padding: "2em", fontSize: "400%" }}>
            {selectedUserKey}
          </div>
        )}
        <div>
          Picking from {Object.keys(userKeyWeights).length} remaining keys.
        </div>
        <div style={{ padding: "4em" }}>
          <button
            onClick={selectUserKey}
            style={{
              padding: "0.5em 1em",
              borderRadius: "100px",
              fontSize: "120%",
            }}
          >
            Select a new key
          </button>
        </div>
        <div>
          <span
            onClick={resetUserKeys}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Reset key list
          </span>
        </div>
        {/*{JSON.stringify(userKeyWeights)}*/}
      </div>
    </div>
  );
}
