const keyWeights = {
  "C# Minor Harmonic": 0.1,
  "Ab Major": 0.1,
  "A Minor Melodic": 0.1,
  "F Minor Harmonic": 0.1,
  "D#/Eb Minor Melodic": 0.1,
  "G#/Ab Minor Melodic": 0.1,
  "G Minor Harmonic": 0.1,
  "D Major": 0.1,
  "A#/Bb Minor Harmonic": 0.1,
  "C Minor Melodic": 0.1,
  "B Minor Harmonic": 0.1,
  "C Minor Harmonic": 0.1,
  "Bb Major": 0.1,
  "E Major": 0.1,
  "F Minor Melodic": 0.1,
  "B Minor Melodic": 0.1,
  "F# Minor Harmonic": 0.1,
  "A Major": 0.1,
  "E Minor Melodic": 0.1,
  "F Major": 0.1,
  "Db/C# Major": 0.1,
  "F#/Gb Major": 0.1,
  "D Minor Harmonic": 0.1,
  "G#/Ab Minor Harmonic": 0.1,
  "G Major": 0.1,
  "Eb Major": 0.1,
  "D Minor Melodic": 0.1,
  "G Minor Melodic": 0.1,
  "C Major": 0.1,
  "C# Minor Melodic": 0.1,
  "A Minor Harmonic": 0.1,
  "D#/Eb Minor Harmonic": 0.1,
  "F# Minor Melodic": 0.1,
  "E Minor Harmonic": 0.1,
  "B/Cb Major": 0.1,
  "A#/Bb Minor Melodic": 0.1,
};

export default keyWeights;
