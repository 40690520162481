import "./App.css";
import { Outlet } from "react-router-dom";

function App({ children }) {
  return (
    <div style={{ padding: "2em", textAlign: "center" }}>
      <Outlet />
    </div>
  );
}

export default App;
